/**
 * Holds all components we want to render on this page
 * @type {{string: SvelteComponent}}
 */
import { mount } from 'svelte'

const componentMap = {}

export function register(component) {
  Object.entries(component).forEach(([key, component]) => {
    if (!componentMap[key]) {
      componentMap[key] = component
    }
  });
  render();
}

function isRendered(target) {
  return target.getAttribute("data-svelte-rendered") === 'true'
}

export function render() {
   document.querySelectorAll(`[data-svelte-component]`).forEach((target) => {
     if (isRendered(target)) {
       return;
     }
     const Component = componentMap[target.getAttribute('data-svelte-component')]
     if (!Component) {
       return;
     }
     let props = {};
     target.setAttribute("data-svelte-rendered", "true");
     if (target.getAttribute('data-svelte-props')) {
       props = JSON.parse(target.getAttribute('data-svelte-props'));
     }
     mount(Component, { target, props });
   });
}