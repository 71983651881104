<script>
  import { csrfToken, csrfParam } from "@/stores/csrf.js";
  import { setContext } from "svelte";
  import { formValidationErrors, formValues } from "@/stores/flash.js";

  let { form = $bindable(), class: klass, action, children, onsubmit } = $props();

  setContext('values', $formValues);
  setContext('validation-errors', $formValidationErrors)
</script>

<form {onsubmit} bind:this={form} {action} method="post" class={klass}>
  <input type="hidden" name="utf8" value="✓"/>
  {#if $csrfToken}
    <input type="hidden" name={$csrfParam} value="{$csrfToken}"/>
  {/if}
  {@render children?.()}
</form>