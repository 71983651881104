<script>
  import localizer from "~/common/i18n.js";
  import FlashMessage from "~/components/FlashMessage.svelte";
  import Form from "~/components/Form.svelte";
  import TextInput from "~/components/TextInput.svelte";
  import Button from "~/components/Button.svelte";
  import LoginBox from "~/components/LoginBox.svelte";
  import SubmitLink from "../../components/login/SubmitLink.svelte";
  import OtpSendStatus from "../../components/OtpSendStatus.svelte";
  import OtpInput from "../../components/OtpInput.svelte";

  /**
   * @typedef {Object} Props
   * @property {any} credentialUuid
   * @property {any} transport
   * @property {any} recipient
   * @property {any} changeRecipientUrl
   * @property {any} [filteredParams]
   * @property {boolean} [userCreated]
   * @property {boolean} [enableResend]
   * @property {any} [verifyCodePath]
   */

  /** @type {Props} */
  let {
    credentialUuid,
    transport,
    recipient,
    changeRecipientUrl,
    filteredParams = {},
    userCreated = false,
    enableResend = true,
    verifyCodePath = `/aid/logg_inn/verifiser_kode/${credentialUuid}`
  } = $props();

  const t = localizer({
    nb: {
      header: "Skriv engangskoden",
      smsInfo:
        'Sjekk mobilen din. Vi har nå sendt en SMS med en engangskode til mobilnummer <span class=\\"aid-login-enter-code-recipient\\">{{recipient}}</span>',
      emailInfo:
        'Sjekk e-posten din. Vi har nå sendt en e-post med en engangskode til <span class=\\"aid-login-enter-code-recipient\\">{{recipient}}</span>',
      code: "Skriv engangskoden her:",
      button: "Fortsett",
      note: "Mottar du ikke engangskoden?",
      resend: "Trykk her for å få en ny.",
    },
    nn: {
      header: "Skriv eingongskoden",
      smsInfo:
        'Sjekk mobilen din. Vi har no sendt ein SMS med ein eingongskode til mobilnummer <span class=\\"aid-login-enter-code-recipient\\">{{recipient}}</span>',
      emailInfo:
        'Sjekk e-posten din. Vi har no sendt ein e-post med en eingongskode til <span class=\\"aid-login-enter-code-recipient\\">{{recipient}}</span>',
      code: "Skriv eingongskoden her:",
      button: "Fortsett",
      note: "Får du ikkje eingongskoden?",
      resend: "Trykk her for å få ein ny.",
    },
  });
</script>

<LoginBox title={t("header")}>
  <OtpSendStatus credentialUUID={credentialUuid}>
    <FlashMessage />

    <p>
      {@html t(transport + "Info", { recipient })}
      {#if changeRecipientUrl}
        (<a href={changeRecipientUrl}>endre</a>)
      {/if}.
    </p>

    <Form action={verifyCodePath}>
      {#each Object.entries(filteredParams) as [key, value]}
        <input type="hidden" name={key} {value} />
      {/each}
      <input type="hidden" name="user_created" value={userCreated} />
      <OtpInput label={t("code")} />

      <Button prio={true}>{t("button")}</Button>
    </Form>
  </OtpSendStatus>

  {#if enableResend}
    <Form action="/aid/logg_inn/send_engangskode/{credentialUuid}">
      {#each Object.entries(filteredParams) as [key, value]}
        <input type="hidden" name={key} {value} />
      {/each}
      <p class="my-aid-msg aid-login-msg--notice">
        {t("note")}
        <SubmitLink>{t("resend")}</SubmitLink>
      </p>
    </Form>
  {/if}
</LoginBox>

<style>
    .my-aid-msg {
        padding: 15px;
        font-size: 18px;
        color: var(--black);
    }
</style>