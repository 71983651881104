<script>
import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
import localizer from "~/common/i18n.js";
import {performWebAuthnSudomodeCeremony} from "~/common/webAuthn.js";

    /**
     * @typedef {Object} Props
     * @property {boolean} [prio]
     */

    /** @type {Props} */
    let { prio = true } = $props();

const t = localizer({
    nb: {
        title: 'Passordfri identifikasjon',
    },
    nn: {
        title: 'Passordfri identifikasjon',
    }
})
</script>

<WebauthnButton prio={prio} logName="login_webauthn" onclick={performWebAuthnSudomodeCeremony}>{t('title')}</WebauthnButton>

<style>
</style>
