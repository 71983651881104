<script>
  import AidLogo from "~/components/AidLogo.svelte";

  /**
   * @typedef {Object} Props
   * @property {string} [title]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let { title = '', children } = $props();
</script>

<header>
  <h2>{title}{@render children?.()}</h2>
  <div class="aid-logo">
    <AidLogo />
  </div>
</header>

<style>
    @import "../common/colors.css";
    header {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        /* this last margin should not be here. but requires a lot of changes to circumvent */
        margin: 0 -20px 20px;
        border-bottom: 2px solid var(--gray);
    }
    h2 {
        font-size: 20px;
        font-weight: bold;
    }
    .aid-logo {
        width: 40px;
        margin-left: 10px;
        flex-shrink: 0;
    }
</style>