<script>
  /**
   * @typedef {Object} Props
   * @property {string} [type]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let { type = "button", children, onclick } = $props();
</script>

<button {type} {onclick}>{@render children?.()}</button>

<style>
  button {
      display: inline;
      padding: 0;
      margin: 0;
      border: none;
      text-decoration: underline;
      background-color: transparent;
      text-align: left;
      color: var(--linkColor);
      cursor: pointer;
  }

  @media (hover: hover) and (pointer: fine) {
      button:hover {
          text-decoration: none;
      }
  }

  button:active {
      text-decoration: none;
  }

</style>