<script>
  import localizer from "@/common/i18n.js";
  import WebauthnRegistrationButton from "@/components/webauthn/WebauthnRegistrationButton.svelte";
  import WebauthnLogo from "~/components/webauthn/WebauthnLogo.svelte";
  import MessageBox from "@/components/MessageBox.svelte";
  import WebauthnRegistrationComplete from "~/pages/login/WebauthnRegistrationComplete.svelte";
  import LoginBox from "@/components/LoginBox.svelte";

  let { continueUrl } = $props();
  let registrationComplete = $state(false);
  let showWebAuthnRegistrationError = $state();

  const t = localizer({
    nb: {
        header_registration: 'Registrer en passnøkkel',
        register_information: 'Når du trykker på knappen under, vil din nettleser be deg om å opprette en passnøkkel for www.aid.no. Denne nøkkelen vil la deg logge inn passordfritt i denne nettleseren, på alle nettsteder som bruker aID.',
        registration_aborted: 'Passnøkkelen ble ikke registrert. Dette kan skyldes at prosessen ble avbrutt, eller at du allerede har brukt samme nøkkel tidligere. Prøv gjerne igjen.',
        continue_without_passkey: 'Fortsett uten å registrere passnøkkel',
    },
    nn: {
        header_registration: 'Registrer ein passnøkkel',
        register_information: 'Når du trykkjer på knappen under, vil nettlesaren din be deg om å opprette ein passnøkkel for www.aid.no. Denne nøkkelen vil la deg logge inn passordfritt i denne nettlesaren, på alle nettstader som bruker aID.',
        registration_aborted: 'Passnøkkelen vart ikkje registrert. Dette kan komma av at prosessen vart avbroten, eller at du allereie har brukt same nøkkel tidlegare. Prøv gjerne igjen.',
        continue_without_passkey: 'Fortsett utan å registrere passnøkkel',
    },
  })

  function goToDestination() {
      window.location.href = continueUrl;
  }
</script>

{#if !registrationComplete}
    <LoginBox title={t('header_registration')}>
        <div class="info registration">
            <WebauthnLogo />
            <p>{t('register_information')}</p>
        </div>
        {#if showWebAuthnRegistrationError}
            <MessageBox type="error" cta={t('continue_without_passkey')} onCta={goToDestination}>
                {t('registration_aborted')}
            </MessageBox>
        {/if}
        <WebauthnRegistrationButton
                onBegin={() => showWebAuthnRegistrationError = false}
                onComplete={() => registrationComplete = true}
                onError={(err, reason) => showWebAuthnRegistrationError = true} />
    </LoginBox>
{:else}
    <WebauthnRegistrationComplete {continueUrl} />
{/if}

<style>
    .info {
        display: flex;
        gap: 1.3rem;
        --svg-square-size: 67px
    }
    .info p {
        flex: 1;
    }
    p:last-child {
        margin-bottom: 0;
    }
</style>
