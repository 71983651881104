<script>
    /**
     * @typedef {Object} Props
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let { children } = $props();
</script>

<a href="./" onclick={(e) => {e.preventDefault(); e.target.closest('form').submit()}}>{@render children?.()}</a>

<style>
    @import "../../common/colors.css";

    a {
        text-decoration: underline;
        color: var(--linkColor);
        cursor: pointer;
    }
</style>