<script>
    import Button from "~/components/Button.svelte";
    import WebauthnLogo from "~/components/webauthn/WebauthnLogo.svelte";

    /**
     * @typedef {Object} Props
     * @property {boolean} [prio]
     * @property {string} [logName]
     * @property {any} [href]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let {
        prio = false,
        logName = 'webauthn',
        href = undefined,
        children,
        onclick
    } = $props();
</script>

<Button {prio} {href} borderless={true} {logName} {onclick}>
    <div class="button_content {prio ? 'inverted' : ''}">
        <WebauthnLogo inverted={true} --svg-square-size="35px" />
        <span>{@render children?.()}</span>
    </div>
</Button>

<style>
    @import "../../common/colors.css";

    .button_content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }
</style>