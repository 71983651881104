<script>
  import { onMount } from "svelte";
  import dialogPolyfill from "dialog-polyfill";

  /**
   * @typedef {Object} Props
   * @property {any} dialog
   * @property {string} [classNames]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let { dialog = $bindable(), classNames = '', children, onclose } = $props();

  onMount(() => dialogPolyfill.registerDialog(dialog));
</script>

<dialog bind:this={dialog} {onclose} class={classNames}>
  {@render children?.()}
</dialog>
