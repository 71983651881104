<script>
    import {onMount} from "svelte";

  let { url } = $props();

    let status=$state("loading");
    let image = $state();

    onMount(() => {
      if (url) {
        image.onload = () => {
          status = "loaded";
        }
      }
    });
</script>

<div class={status}>
  <svg class="avatar-placeholder" xmlns="http://www.w3.org/2000/svg" viewBox="-312 -362 1024 1024" enable-background="new -312 -362 1024 1024">
    <path fill="#ccc" d="m552.8 420.1c-137.9-30.6-266.2-69.2-204-181.4 189.2-342 50.2-530.7-149.7-530.7-203.6 0-339.2 186.9-149.5 521.7 64 113.1-69.2 161.9-204.1 191.6-137.9 30.7-127.3 119.6-127.3 248.9h961.8c0-129.4 10.7-219.7-127.2-250.1"/>
  </svg>
  {#if url}
    <img class="avatar" alt="Profilbilde" src={url} bind:this={image}/>
  {/if}
</div>


<style>
    @import '../../common/colors.css';

    div {
        border-radius: 100%;
        border: 1px solid var(--lightGray);
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
    }

    div .avatar-placeholder, div .avatar {
        width: 100%;
        height: 100%;
        transition: opacity 250ms ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
    }

    div.loading .avatar-placeholder {
        opacity: 1;
    }

    div.loaded .avatar-placeholder {
      opacity: 0;
    }

    div.loading .avatar {
      opacity: 0;
    }

    div.loaded .avatar {
      opacity: 1;
    }
</style>