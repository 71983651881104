<script>
    import StyledInputField from "./StyledInputField.svelte";
    import LabelledFormInput from "./LabelledFormInput.svelte";
    import {tick} from "svelte";

    /**
     * @typedef {Object} Props
     * @property {string} [label]
     * @property {string} [type]
     * @property {any} name
     * @property {string} [value]
     * @property {boolean} [required]
     * @property {any} maxLength
     * @property {any} autocomplete
     * @property {any} placeholder
     * @property {boolean} [visuallyHidden]
     */

    /** @type {Props} */
    let {
        label = '',
        type = 'text',
        name,
        value = $bindable(''),
        required = false,
        maxLength,
        autocomplete,
        placeholder,
        visuallyHidden = false,
        onchange,
        oninput
    } = $props();

    let input = $state();

    export async function focus() {
        await tick();
        input.focus();
    }
</script>

<LabelledFormInput {name} {label} {visuallyHidden}>
    <StyledInputField {name} bind:this={input} bind:value={value} {type} {required} {maxLength} {autocomplete} {placeholder} {visuallyHidden} {oninput} {onchange} />
</LabelledFormInput>

<style>

</style>