/* global globalThis */
import { amediaFetch } from '@amedia/fetch';

function getCdnVersion() {
  const urlParams = new URLSearchParams(globalThis.location?.search);
  const flowPlayerTest = urlParams.get('flowplayer_test');
  if (flowPlayerTest) {
    if (flowPlayerTest === 'test') {
      return '3/canary';
    }
    if (/^\d\/v\d\.\d{1,2}\.\d{1,2}$/.test(flowPlayerTest)) {
      return flowPlayerTest;
    }
  }
  return '';
}

function resolve(file) {
  if (import.meta.resolve) {
    return import.meta.resolve(file);
  }
  const { href } = new URL(file, import.meta.url);
  return href;
}

function loadCss() {
  // imported + re-exported in re-export-flowplayer-css.js
  const href = resolve('../css/flowplayer.css');
  const links = document.querySelectorAll(`link[href='${href}']`);

  if (links.length === 0) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.setAttribute('data-status', 'loading');
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

const loadScript = (src) => {
  if (document.querySelector(`script[src="${src}"]`)) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = src;
    el.onload = resolve;
    el.onerror = reject;
    document.head.appendChild(el);
  });
};

async function loadPlayerConfig({ playerId, mediaId }) {
  const res = await amediaFetch(
    `https://ljsp.lwcdn.com/web/public/native/config/${playerId}/${mediaId}`,
    {
      timeout: 30000, // 30 seconds
    }
  );
  return await res.json();
}

function loadGoogleIma({ debugLogging }) {
  return loadScript(
    `https://imasdk.googleapis.com/js/sdkloader/${
      debugLogging ? 'ima3_debug' : 'ima3'
    }.js`
  );
}

function isDivCollapsed() {
  let hasCheckedIfDivCollapsed = false;

  function checkIfDivCollapsed(resolve) {
    if (!hasCheckedIfDivCollapsed) {
      hasCheckedIfDivCollapsed = true;
      const element = document.createElement('div');
      element.innerHTML = '&nbsp;';
      element.className = 'ads adsbox ad-placement bottom-ad';
      element.style.position = 'absolute';
      element.style.left = '0px';
      element.style.top = '0px';
      element.style.width = '1px';
      element.style.height = '1px';
      document.body.appendChild(element);

      globalThis.setTimeout(() => {
        resolve(element.offsetHeight === 0);
        if (element.remove === undefined) {
          return element.domRefElement.removeChild(element);
        }
        return element.remove();
      }, 100);
    }
  }

  return new Promise((resolve) => {
    document.addEventListener('DOMContentLoaded', () =>
      checkIfDivCollapsed(resolve)
    );
    if (document.readyState === 'complete') {
      checkIfDivCollapsed(resolve);
    }
    globalThis.setTimeout(() => checkIfDivCollapsed(resolve), 1000);
  });
}

export default async function bootstrapFlowplayer({
  element,
  isAdsDisabled,
  isDebugLogging,
  playerId,
  mediaId,
}) {
  loadCss();
  const [adblocked, ovpConfig] = await Promise.all([
    isDivCollapsed(),
    loadPlayerConfig({ playerId, mediaId }).catch(() => ({
      metadata: {},
      src: [],
    })),
  ]);
  element.setAttribute('adblockchecked', '');
  // allow testing past/future versions of flowplayer
  const version = getCdnVersion();
  if (version) {
    return await import('./flowplayerCdn.js').then((bootstrapFlowplayer) => {
      return bootstrapFlowplayer
        .default({
          adblocked,
          element,
          version,
          isAudio: false,
          isAdsDisabled,
          isDebugLogging,
        })
        .then((flowplayer) => ({
          ovpConfig,
          flowplayer,
        }));
    });
  }
  if (adblocked || isAdsDisabled) {
    const { flowplayer } = await import('./flowplayerNoAds.js');
    return { ovpConfig, flowplayer };
  }
  const { flowplayer } = await import('./flowplayerAds.js');
  await loadGoogleIma({
    debugLogging: isDebugLogging,
  });
  return { ovpConfig, flowplayer };
}
