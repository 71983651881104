<script>
    import localizer from "~/common/i18n.js";
    import exclamationCircle from "@/images/icons/exclamation-circle.svg?raw";
    import exclamationTriangle from "@/images/icons/exclamation-triangle.svg?raw";
    import InlineSVG from "./InlineSVG.svelte";
    import ExpandableBox from "./ExpandableBox.svelte";

    let expanded = $state(false);
    /**
     * @typedef {Object} Props
     * @property {any} [theme]
     * @property {boolean} [collapsibleContent]
     * @property {any} title
     * @property {any} [body]
     * @property {any} [link]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let {
        theme = undefined,
        collapsibleContent = false,
        title,
        body = undefined,
        link = undefined,
        children
    } = $props();
    
    const t = localizer({
        nb: {
            readMore: 'Les mer',
            readLess: 'Les mindre',
        },
        nn: {
            readMore: 'Les meir',
            readLess: 'Les mindre',
        },
    });
</script>

<div class="infoBox {theme ? theme : ''}" class:expandable={collapsibleContent}>
    <div class="info-box-icon exclamation-{theme === 'secondary' ? 'triangle' : 'circle'}">
        <InlineSVG svg={theme === 'secondary' ? exclamationTriangle : exclamationCircle} --svg-square-size="20px" />
    </div>
    <div class="info-box-text">
        {#if title}
            <h2 class="title">{title}</h2>
        {/if}

        {#if collapsibleContent}
            <ExpandableBox headerText={expanded ? t('readLess') : t('readMore')} bind:expanded={expanded} --buttonDirection="row-reverse" --buttonJustify="flex-end">
                <div class="info-box-content">
                    {#if body}
                        <p>{body}</p>
                    {/if}

                    {@render children?.()}

                    {#if link}
                        <a href={link.url}>{link.text}</a>
                    {/if}
                </div>
            </ExpandableBox>
        {:else}
            <div class="info-box-content">
                {#if body}
                    <p>{body}</p>
                {/if}

                {@render children?.()}

                {#if link}
                    <a href={link.url}>{link.text}</a>
                {/if}
            </div>
        {/if}
    </div>
</div>

<style>
    @import "../common/colors.css";

    .infoBox {
        padding: 20px;
        background: var(--lightGray);
        border: 3px solid var(--lightGray);
        display: flex;
        color: var(--black);
    }

    .infoBox.secondary{
        background: transparent;
    }


    .infoBox .info-box-content {
        width: 100%;
        padding: 0;
        opacity: 1;
    }

    .infoBox .title{
        color: var(--black);
        font-size: 19px;
        margin-top: 0;
        line-height: 1.2;
        margin-bottom: .5rem;
    }

    .info-box-icon{
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        flex-shrink: 0;
    }

    .info-box-text {
        margin: 0 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .info-box-text p {
        margin-bottom: 1rem;
    }

    .info-box-text p:last-child{
        margin-bottom: 0;
    }
</style>