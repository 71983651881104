<script>
    import LabelledFormInput from "./LabelledFormInput.svelte";
    import InlineSVG from "./InlineSVG.svelte";
    import ClickOutside from "./ClickOutside.svelte";
    import StyledInputField from "./StyledInputField.svelte";
    import no from "@/images/icons/flags/no.svg?raw";
    import se from "@/images/icons/flags/se.svg?raw";
    import dk from "@/images/icons/flags/dk.svg?raw";
    import fi from "@/images/icons/flags/fi.svg?raw";
    import es from "@/images/icons/flags/es.svg?raw";
    import pl from "@/images/icons/flags/pl.svg?raw";
    import ua from "@/images/icons/flags/ua.svg?raw";
    import {wait} from "../common/utils.js";
    import {tick} from "svelte";
    import Dialog from "@/components/Dialog.svelte";

    /**
     * @typedef {Object} Props
     * @property {string} [label]
     * @property {string} [type]
     * @property {any} name
     * @property {string} [value]
     * @property {string} [autocomplete]
     * @property {boolean} [required]
     */

    /** @type {Props} */
    let {
        label = '',
        type = 'text',
        name,
        value = $bindable(''),
        autocomplete = 'tel',
        required = false,
        onchange,
        oninput
    } = $props();

    let phoneInput = $state();
    let countryCodeSelector = $state();
    let countryCodeSelectorOpened = $state(false);

    const countries = [
        {
            code: 47,
            flag: no
        },
        {
            code: 46,
            flag: se,
        },
        {
            code: 45,
            flag: dk,
        },
        {
            code: 358,
            flag: fi,
        },
        {
            code: 34,
            flag: es,
        },
        {
            code: 48,
            flag: pl,
        },
        {
            code: 380,
            flag: ua,
        }
    ];

    let country = $derived(countries.filter((c) => value?.startsWith('+'+c.code))[0]);

    function showCountryCodeSelector() {
        countryCodeSelectorOpened = true;
        countryCodeSelector.show();
    }

    async function closeCountryCodeSelector() {
        countryCodeSelectorOpened = false;
        await wait(250);
        if (!countryCodeSelectorOpened) {
            countryCodeSelector.close();
        }
    }

    function toggleCountryCodeSelector() {
        if (countryCodeSelectorOpened || countryCodeSelector.open) {
            closeCountryCodeSelector();
        } else {
            showCountryCodeSelector();
        }
    }

    async function selectCountry(e, newCountry) {
        e.preventDefault();

        if (country) {
            value = value.replace('+' + country.code, '+' + newCountry.code);
        } else {
            value = '+' + newCountry.code + (value || '');
        }
        await closeCountryCodeSelector();
        phoneInput.focus();
    }

    export async function focus() {
        await tick();
        phoneInput.focus();
    }

</script>

<LabelledFormInput {name} {label}>
    <div class="username_container showFlag">
        <div class="country_selector">
            <button type="button" onclick={toggleCountryCodeSelector}>
                <InlineSVG svg={country?.flag || no} --svg-square-size="16px" --borderRadius="100%" />
                <svg width="13" height="8" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="-0.5" x2="17.765" y2="-0.5" transform="matrix(0.679483 0.733691 -0.679483 0.733691 0.786942 1.72327)" stroke="var(--fieldGray)" stroke-width="4px"/>
                    <line y1="-0.5" x2="17.765" y2="-0.5" transform="matrix(-0.679483 0.733691 -0.679483 -0.733691 24.929 1.72327)" stroke="var(--fieldGray)" stroke-width="4px"/>
                </svg>
            </button>
            <Dialog bind:dialog={countryCodeSelector} classNames={countryCodeSelectorOpened ? 'isOpen' : ''} aria-hidden={!countryCodeSelectorOpened} tabindex={countryCodeSelectorOpened ? 0 : -1}>
                <ClickOutside onOutsideClicked={closeCountryCodeSelector} enabled={countryCodeSelectorOpened}>
                    <div>
                        {#each countries as country}
                            <button type="button" onclick={(e) => selectCountry(e, country)} tabindex={countryCodeSelectorOpened ? 0 : -1}>
                                <InlineSVG svg={country.flag} --svg-square-size="16px" --borderRadius="100%" />
                                <span>+{country.code}</span>
                            </button>
                        {/each}
                    </div>
                </ClickOutside>
            </Dialog>
        </div>
        <StyledInputField
                bind:this={phoneInput}
                {name}
                bind:value={value}
                type='tel'
                {autocomplete}
                {required}
                {oninput}
                {onchange}
        />
    </div>
</LabelledFormInput>

<style>
    .username_container {
        display: grid;
        grid-template-columns: 0 1fr;
        transition: grid-template-columns 250ms ease-in-out;
    }

    .username_container.showFlag {
        grid-template-columns: auto 1fr;
    }

    .country_selector {
        overflow: clip;
        padding: 0;
        opacity: 0;
        transition: padding 250ms ease-in-out, opacity 250ms ease-in-out 150ms;
        border-right: 2px solid var(--fieldGray);
    }

    span {
        display: block;
    }

    .showFlag .country_selector {
        padding: 0 .5rem;
        opacity: 1;
    }

    .country_selector button,
    .country_selector > :global(dialog button) {
        height: 100%;
        display: flex;
        gap: .5rem;
        border: 0;
        background: none;
        position: relative;
        align-items: center;
    }

    @supports not (display: flex) {
        /*
         * Give some gap to browser that lack flex support,
         * namely iOS 12..
         */
        .country_selector:first-child {
            margin-right: .5rem;
        }
    }

    .country_selector > :global(dialog) {
        position: absolute;
        display: block;
        margin-top: 0;
        margin-left: 0;
        margin-right: auto;
        padding: 0;
        top: 100%;
        left: -2px;
        z-index: 100;
        border: 2px solid var(--fieldGray);
        transform: scaleY(0);
        opacity: 0;
        transform-origin: top;
        transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    }

    .country_selector > :global(dialog.isOpen) {
        transform: scaleY(1);
        opacity: 1;
    }

    .country_selector button {
        padding: 0;
    }

    .country_selector > :global(dialog.isOpen button) {
        padding: .5rem;
    }
</style>