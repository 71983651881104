<script>
    import LinkButton from "@/components/LinkButton.svelte";
    import ClickOutside from "@/components/ClickOutside.svelte";
    import Button from "./Button.svelte";
    import Dialog from "@/components/Dialog.svelte";
    /**
     * @typedef {Object} Props
     * @property {any} label
     * @property {string} [header]
     * @property {boolean} [showAsLink]
     * @property {boolean} [prio]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let {
        label,
        header = '',
        showAsLink = true,
        prio = true,
        children
    } = $props();

    let overlay = $state();
    let open = $state(false);

    function openOverlay() {
        open = true;
        overlay.showModal();
    }

    function closeOverlay() {
        open = false;
        overlay.close();
    }
</script>

<div>
    <Dialog bind:dialog={overlay}>
        <ClickOutside onOutsideClicked={closeOverlay} enabled={open}>
            <div class="content">
                <h2 class="main-title">{header}</h2>
                <button type="button" class="close" aria-label="Lukk" onclick={closeOverlay}>&times</button>
                {@render children?.()}
            </div>
        </ClickOutside>
    </Dialog>
</div>

{#if showAsLink}
    <LinkButton onclick={openOverlay}>{label}</LinkButton>
{:else}
    <Button {prio} onclick={openOverlay}>{label}</Button>
{/if}

<style>
@import '../common/stacking.css';
@import '../common/colors.css';

:root {
    --easing: cubic-bezier(0.445, 0.050, 0.550, 0.950);
}

div :global(dialog) {
    background: var(--white);
    position: fixed;
    top: 5vh;
    bottom: 5vh;
    left: 5vh;
    right: 5vh;
    overflow-y: auto;
    padding: 0;
    border: 0;
}

div :global(dialog .content) {
    padding: 20px;
}

@media screen and (max-width: 1000px) {
    div :global(dialog .content) {
        padding: 2vw;
    }
}

@media screen and (max-width: 600px) {
    div :global(dialog) {
        top:1vh;
        left:1vh;
        bottom: 1vh;
        right: 1vh;
    }
}

div :global(dialog::backdrop),
div :global(dialog + .backdrop) /* added due to limitation of polyfill (@see https://github.com/GoogleChrome/dialog-polyfill?tab=readme-ov-file#backdrop) */ {
    content: ' ';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
}

div :global(dialog .close) {
    background: none;
    border: none;
    padding: 0;
    transition: 250ms var(--easing);
}

div :global(dialog .close:hover) {
    transform: scale(1.1);
}

div :global(dialog .content > .close) {
    display: block;
    color: var(--white);
    position: absolute;
    top: -5px;
    right: 20px;
    font-size: 50px;
    cursor: default;
}

@media screen and (max-width: 1000px) {
    div :global(dialog .content > .close) {
        top: -.5vw;
        right: 2vw;
    }
}

@media screen and (max-width: 500px) {
    div :global(dialog .content > .close) {
        top: -1vw;
        right: 2vw;
    }
}

.main-title {
    background: var(--black);
    color: var(--white);
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    margin: -20px -20px 20px -20px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .main-title {
        padding: 1vw 2vw;
        margin: -2vw -2vw 2vw -2vw;
    }
}

@media screen and (max-width: 500px) {
    .main-title {
        padding: 2vw 4vw;
        margin: -2vw -2vw 2vw -2vw;
    }
}
</style>