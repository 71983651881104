<script>
    import {generateUuid} from "../common/utils.js";

    /**
     * @typedef {Object} Props
     * @property {boolean} [enabled]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props & { [key: string]: any }} */
    let { enabled = false, children, onOutsideClicked, ...rest } = $props();

    const uuid = generateUuid();

    let wrapper = $state();

    const handler = (e) => {
        if (isOutside(e.target) && onOutsideClicked) {
            e.stopPropagation();
            onOutsideClicked();
        }
    }

    $effect(() => {
        if (enabled) {
            globalThis.addEventListener('pointerdown', handler);
        } else {
            globalThis.removeEventListener('pointerdown', handler);
        }
    });

    function isOutside(target) {
        return !wrapper.contains(target);
    }
</script>

<div
    {uuid}
    bind:this={wrapper}
    role="button"
    tabindex="0"
    style="display: contents"
    onclick={handler}
    {...rest}
>
    {@render children?.()}
</div>