<script>
  /**
   * @typedef {Object} Props
   * @property {any} headerText
   * @property {boolean} [expanded]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let { headerText, expanded = $bindable(false), children } = $props();

  function toggleExpanded(evt) {
    evt.stopImmediatePropagation();
    expanded = !expanded
  }
</script>

<div class="wrapper {expanded ? 'expanded' : ''}">
  <button onclick={toggleExpanded}>
    <span>{headerText}</span>
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-0.5" x2="17.765" y2="-0.5" transform="matrix(0.679483 0.733691 -0.679483 0.733691 0.786942 1.72327)" stroke="black" stroke-opacity="0.5"/>
      <line y1="-0.5" x2="17.765" y2="-0.5" transform="matrix(-0.679483 0.733691 -0.679483 -0.733691 24.929 1.72327)" stroke="black" stroke-opacity="0.5"/>
    </svg>
  </button>
  <div>
    {@render children?.()}
  </div>
</div>

<style>
  .wrapper {
     background-color: #F4F4F4;
     font-size: 14px;
  }
  .wrapper > div {
      overflow-y: hidden;
      transition: max-height 200ms ease;
      max-height: 0;
  }
  .wrapper.expanded > div {
      max-height: 1000px;
  }
  .wrapper > :global(div > *:not(:last-child)) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.22);
  }
  .wrapper button,
  .wrapper > :global(div > *) {
      padding: 1rem;
  }
  .wrapper > :global(div > *:first-child) {
      padding-top: 0;
  }
  .wrapper button {
      display: flex;
      justify-content: var(--buttonJustify, space-between);
      gap: 8px;
      flex-direction: var(--buttonDirection, row);
      background: none;
      align-items: center;
      font-weight: 700; color: var(--black);
      width: 100%;
      box-sizing: border-box;
      border: 0;
  }

  .wrapper svg {
      /* Height and width lock required for Webkit */
      height: 1rem;
      width: 1.6rem;
      border: none;
      background-color: transparent;
  }
  .wrapper svg {
      transition: transform 200ms;
  }
  .wrapper.expanded svg {
      transform: scaleY(-1);
  }
</style>