<script>
import WebauthnLogo from "~/components/webauthn/WebauthnLogo.svelte";
import Button from "~/components/Button.svelte";
import localizer from "~/common/i18n.js";
import LoginBox from "~/components/LoginBox.svelte";

    let { continueUrl } = $props();

const t = localizer({
    nb: {
        header_complete: 'Ferdig! Du har nå aktivert passordfri innlogging på din aID',
        register_complete: 'Du er innlogget med passordfri innlogging, og kan bruke dette hver gang du logger inn med aID i denne nettleseren.',
        continue: 'Fortsett',
    },
    nn: {
        header_complete: 'Ferdig! Du har no aktivert passordfri innlogging på din aID',
        register_complete: 'Du er innlogga med passordfri innlogging, og kan bruka dette kvar gong du loggar inn med aID i denne nettlesaren.',
        continue: 'Fortsett',
    },
})

</script>

<LoginBox title={t('header_complete')}>
    <div class="wrapper">
        <div class="info">
            <WebauthnLogo />
            <p>{t('register_complete')}</p>
        </div>
        <Button prio="true" href={continueUrl}>{t('continue')}</Button>
    </div>
</LoginBox>

<style>
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .info {
        display: flex;
        gap: 1.3rem;
        --svg-square-size: 67px
    }
    .info p {
        flex: 1;
    }
    p:last-child {
        margin-bottom: 0;
    }
</style>
