<script>
    import localizer from "~/common/i18n.js";
    import LoginHeader from "../../components/LoginHeader.svelte";
    import {capitalize} from "../../common/utils.js";
    import Button from "../../components/Button.svelte";

    let { vendor, profileFrontUrl, requestedUrl } = $props();

    const t = localizer({
        nb: {
            header: `Ferdig! Du har nå koblet {{vendor}} til din aID`,
            info1: `Du er innlogget med {{vendor}}, og kan bruke {{vendor}} som innlogging på alle nettsteder som bruker aID for innlogging. Hvis du ønsker å bryte koblingen mellom {{vendor}} og aID, så gå til `,
            myAid: 'aID-en din',
            info2: 'Her kan du også endre opplysninger om deg selv og personverninnstillinger, samt se annen relevant informasjon.',
            button: 'Fortsett, du er snart i mål!'
        },
        nn: {
            header: `Ferdig! Du har no kopla {{vendor}} til din aID`,
            info1: `Du er innlogga med {{vendor}}, og kan bruke {{vendor}} som innlogging på alle nettstader som bruker aID. Viss du ønskjer å bryte koplinga mellom {{vendor}} og aID, så gå til `,
            myAid: 'aID-en din',
            info2: 'Her kan du også endre opplysningar om deg sjølv og personverninnstillingar, og sjå annan relevant informasjon.',
            button: 'Fortsett, du er snart i mål!',
        }
    });

</script>

<LoginHeader title={t('header', {vendor: capitalize(vendor)})} />

<article class="my-aid-pad--bottom text-block">
    <p>
        {t('info1', {vendor: capitalize(vendor)})}
        <a href={profileFrontUrl}>{t('myAid')}</a>.
    </p>
    <p>
        {t('info2')}
    </p>
    <Button prio={true} logName="connect_to_external" href={requestedUrl}>{t('button')}</Button>
</article>

<style>
    article {
        display: grid;
        gap: 16px;
    }
</style>