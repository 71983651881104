<script>
    import LoginHeader from "~/components/LoginHeader.svelte";

    /**
     * @typedef {Object} Props
     * @property {string} [title]
     * @property {import('svelte').Snippet} [children]
     */

    /** @type {Props} */
    let { title = "", children } = $props();
</script>

<div>
    <LoginHeader {title} />
    <div class="box_content">
        {@render children?.()}
    </div>
</div>

<style>
    div .box_content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 4px;
    }

    div .box_content :global(p), div .box_content :global(form) {
        margin-bottom: 0;
    }
</style>
