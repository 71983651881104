<script>
    import localizer from "~/common/i18n.js";
    import LoginBox from "../../components/LoginBox.svelte";
    import FlashMessage from "../../components/FlashMessage.svelte";
    import Form from "../../components/Form.svelte";
    import LinkButton from "../../components/LinkButton.svelte";
    import AvatarUpload from "../../components/profile/AvatarUpload.svelte";

    /**
     * @typedef {Object} Props
     * @property {any} [avatarUrl]
     * @property {boolean} [hasAidAvatar]
     * @property {boolean} [hasExternalAvatar]
     * @property {any} profileFrontUrl
     * @property {any} confirmDeleteAvatarUrl
     * @property {any} confirmDisconnectExternalAccountUrl
     * @property {any} connectToFacebookUrl
     * @property {any} uploadUrl
     * @property {any} redirectUrl
     */

    /** @type {Props} */
    let {
        avatarUrl = undefined,
        hasAidAvatar = false,
        hasExternalAvatar = false,
        profileFrontUrl,
        confirmDeleteAvatarUrl,
        confirmDisconnectExternalAccountUrl,
        connectToFacebookUrl,
        uploadUrl,
        redirectUrl
    } = $props();

    const t = localizer({
        nb: {
            header: 'Last opp et profilbilde',
            p1: 'Profilbildet vises for andre når du bidrar med innhold på et nettsted som bruker aID som innlogging, for eksempel på din lokalavis.',
            p2: 'Ved endring kan det ta opptil 5 minutter før bildet vises.',
            facebookPromo: 'Du kan også bruke profilbildet ditt fra Facebook ved å knytte Facebook-kontoen din til aID:',
            facebookLink: 'Koble til Facebook og hent profilbilde.',
            profile: 'Til oversikten',
        }
    });
</script>

<LoginBox title={t('header')}>
    <FlashMessage />

    <p>{t('p1')}</p>
    <AvatarUpload {avatarUrl} {hasAidAvatar} {hasExternalAvatar} {confirmDeleteAvatarUrl} {confirmDisconnectExternalAccountUrl} {redirectUrl} {uploadUrl} />

    {#if !hasExternalAvatar}
        <Form action={connectToFacebookUrl}>
            {t('facebookPromo')}
            <LinkButton type="submit">{t('facebookLink')}</LinkButton>
        </Form>
    {/if}
    <p>{t('p2')}</p>
    <p><a href={profileFrontUrl}>{t('profile')}</a></p>
</LoginBox>


