<script>
  import InlineSVG from "@/components/InlineSVG.svelte";
  import iconSuccess from "@/images/icons/success.svg?raw";
  import iconWarning from "@/images/icons/warning.svg?raw";
  import iconError from "@/images/icons/error.svg?raw";

  
  /**
   * @typedef {Object} Props
   * @property {any} type
   * @property {string} [title]
   * @property {string} [message]
   * @property {any} [cta]
   * @property {import('svelte').Snippet} [children]
   */

  /** @type {Props} */
  let {
    type,
    title = '',
    message = '',
    cta = null,
    onCta,
    children
  } = $props();

  const icons = {
    success: iconSuccess,
    error: iconError,
    warn: iconWarning,
  }
</script>

<div class="wrapper {type}">
  <InlineSVG svg={icons[type]} --svg-square-size="24px" />
  <div>
    {#if title}
      <strong>{title}</strong>
    {/if}
    {message}
    {@render children?.()}
    {#if cta}
      <button onclick={() => {if (onCta) {onCta();}}}>{cta}</button>
    {/if}
  </div>
</div>

<style>
  .wrapper {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 14px 16px;
      border: 2px solid transparent;
      font-size: 0.88rem;
      color: var(--black);
      margin-top: 8px;
      margin-bottom: 8px;
  }
  strong {
      display: block;
  }
  button {
      display: block;
      border: none;
      background-color: transparent;
      padding: 5px 5px 5px 0;
      margin-top: 8px;
      font-weight: 700;
      text-decoration: underline;
  }

  button:hover {
      cursor: pointer;
  }
  .wrapper:global(.success) {
      color: #002900;
      background-color: hsla(120, 100%, 94%, 1);
      border-color: hsla(120, 100%, 25%, 0.4);
  }
  .wrapper:global(.success) button {
      color: #002900;
  }
  .wrapper:global(.error) {
      color: #330006;
      background-color: hsla(353, 100%, 95%, 1);
      border-color: hsla(353, 100%, 40%, 0.5);
  }
  .wrapper:global(.error) button {
      color: #4b2226;
  }
  .wrapper:global(.warn) {
      color: var(--brownOnYellow);
      background-color: hsla(46, 66%, 88%, 1);
      border-color: hsla(46, 66%, 50%, 0.5);
  }
  .wrapper:global(.warn) button {
    color: var(--brownOnYellow);
  }
</style>