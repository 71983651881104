/**
 * Measures if element is inscreen when 50% of the element is in the viewport
 * or if the element is larger than the viewport and 10% of the viewport is covered.
 *
 * You cannot currently manually log inscreen and no logging method is exported from this module.
 * @module lib/events/inscreen
 * @typicalname inscreen
 */

//@ts-ignore
import * as jResizeObserver from "@juggle/resize-observer"
import "../activity";
import { INACTIVITY_TIME } from "../activity";
import { ADPLogEvent } from "../../../declarations/logger";
import { setABTestDataForTeaser } from "../adp-variant";
import { Logger } from "../classes/logger";
import { log } from "../../shared/logger";

// hack for rollup
const Polyfill = jResizeObserver.ResizeObserver;
const ResizeObserver = /** @type {any} */ (window).ResizeObserver || Polyfill;
const elementsCurrentlyInscreen = new WeakMap();
const durationListenerUnload = new WeakMap();
let inscreenObserver: IntersectionObserver;

function calculateTreshold(sendCount: number): number {
  // Calculates scaling logging intervals.
  // const logIntervals = [4000, 4500, 4501, 4502]; // Debug interval
  const logIntervals = [1000, 10000, 20000, 40000, 60000, 80000]; // production interval
  const maxLogInterval = logIntervals.length - 1;
  const logIntervalSelector = Math.min(sendCount, maxLogInterval);
  const durationTreshold = logIntervals[logIntervalSelector];
  return durationTreshold;
}

// @ts-ignore
const resizeObserver = new ResizeObserver((entries) => {
  entries.forEach((entry) => {
    if (elementsCurrentlyInscreen.has(entry.target)) {
      return;
    }
    inscreenObserver.unobserve(entry.target);
    inscreenObserver.observe(entry.target);
  });
});

// createNewLogger(element) is not needed anymore. Can be replaced with new Logger(element);
function createNewLogger(element: Element) {
 
  const data = {
    type: "inscreen",
    duration: 0,
  };
  return new Logger(element);
}

function createDurationListener(element: HTMLElement) {
  let logger = createNewLogger(element);
  logger.lastEventTime = Date.now();
  elementsCurrentlyInscreen.set(element, logger);

  function updateDuration() {
    if (!logger.lastEventTime) {
      logger.lastEventTime = Date.now();
    }
    const justNow = Date.now();
    const maxDuration = calculateTreshold(logger.sendCount);
    // In rare cases of very high duration, send the max duration + inactivity time instead
    const duration = Math.min(
      justNow - logger.lastEventTime,
      maxDuration + INACTIVITY_TIME
    );
    logger.duration = duration;
    // logger.updateDuration({ duration });
  }

  function sendAndContinue() {
    const previousSendCount = logger.sendCount;
    logger.send(err => handleLogError(err))
    logger = createNewLogger(element);
    logger.sendCount = previousSendCount + 1;
  }

  function sendAndStop() {
    updateDuration();
    logger.send(err => handleLogError(err))
    elementsCurrentlyInscreen.delete(element);
    durationListenerUnload.delete(element);
  }

  function forceSend() {
    updateDuration();
    sendAndContinue();
  }

  function userActiveHandler() {
    updateDuration();
    const durationTreshold = calculateTreshold(logger.sendCount);
    if (logger.duration >= durationTreshold) {
      sendAndContinue();
    }
  }

  function unload() {
    sendAndStop();
    window.removeEventListener("adplogger:user-active", userActiveHandler);
    window.removeEventListener("adplogger:user-inactive", forceSend);
    window.removeEventListener("pagehide", unload);
  }
  window.addEventListener("adplogger:user-active", userActiveHandler);
  window.addEventListener("adplogger:user-inactive", forceSend);
  window.addEventListener("pagehide", unload);
  return unload;
}

function retryFailedEvent(logEvent: ADPLogEvent) {
  const { adpType, data, element } = logEvent;

  log<typeof adpType>(adpType, data, element).catch(() => {
    logEvent.attempt += 1;
    handleLogError(logEvent);
  });
}

function handleLogError(logEvent: ADPLogEvent) {
  if (logEvent.attempt > 1) {
    console.error(`Failed sending on retry, aborting. Duration lost: ${logEvent.data.duration}`);
    return;
  }
  setTimeout(() => {
    retryFailedEvent(logEvent);
  }, 5000);
}


function elementLargerThanViewPort(element: Element) {
  let { top, bottom } = element.getBoundingClientRect();
  return bottom - top > window.innerHeight;
}

// Is triggered everytime an element intersects the viewport.
function intersectionHandler(entry) {
  const ratio = entry.intersectionRatio;
  let element = entry.target;
  const isInscreen = elementsCurrentlyInscreen.has(element);
  const scrolledIntoScreen = elementLargerThanViewPort(element)
    ? ratio > 0
    : ratio >= 0.5;

  const scrolledOutofScreen = elementLargerThanViewPort(element)
    ? ratio <= 0
    : ratio < 0.5;
  if (scrolledIntoScreen && !isInscreen) {
    //TODO: Remove impl after AB testing is moved to backend
    setABTestDataForTeaser(element)
    const unload = createDurationListener(element);
    durationListenerUnload.set(element, unload);
  }

  if (scrolledOutofScreen) {
    if (isInscreen && durationListenerUnload.has(element)) {
      // Send duration and remove element from elementsCurrentlyInscreen list
      durationListenerUnload.get(element)();
    }
    return;
  }
}

inscreenObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach(intersectionHandler);
  },
  {
    threshold: [0, 0.5],
  }
);

function disconnectObservers() {
  if (inscreenObserver) {
    inscreenObserver.disconnect();
  }
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
}

window.addEventListener("beforeunload", disconnectObservers);

// Log all tagged elements.
self.addEventListener("adplogger:meta-elements-added", (event: CustomEvent) =>
  /** @type { CustomEvent } */(event).detail.forEach((element) => {
  inscreenObserver.observe(element);
})
);

self.addEventListener("adplogger:meta-elements-log", (event: CustomEvent) => {
  /* setABTestDataForTeaser(event.detail) */
  const unload = createDurationListener(event.detail);
  durationListenerUnload.set(event.detail, unload);
});
