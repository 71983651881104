<script>
    import {Turnstile} from "svelte-turnstile";
    import FormInput from "~/components/FormInput.svelte";

    /**
     * @typedef {Object} Props
     * @property {string} [turnstileKey]
     * @property {string} [action]
     * @property {string} [token]
     */

    /** @type {Props} */
    let { turnstileKey = "", action = "unknown", token = $bindable(""), onInteractionRequired, onCompleted } = $props();

    let hidden = $state(true)
</script>

{#if turnstileKey !== ""}
    <div class:hidden={hidden}>
        <FormInput name="captcha" >
            {#snippet children({ errors })}
                        <Turnstile siteKey={turnstileKey}
                           size="flexible"
                           language="nb-no"
                           theme="light"
                           appearance={errors ? "always" : "interaction-only"}
                           on:before-interactive={() => {
                               hidden = false
                               if (onInteractionRequired) {
                                   onInteractionRequired();
                               }
                           }}
                           on:callback={(result) => {
                               token = result.token;
                               if (onCompleted) {
                                   onCompleted();
                               }
                           }}
                           on:error={({error}) => {console.error("Turnstile error", error)}}
                           {action}/>
                                {/snippet}
                </FormInput>
    </div>
{/if}

<style>
    div.hidden {
        height: 0 !important;
    }

    div:not(.hidden) {
        padding-bottom: 20px;
    }
</style>