<script>
  import MessageBox from "~/components/MessageBox.svelte";
  import { messages } from "@/stores/flash.js";

  let { cta, onCta } = $props();
</script>

{#each $messages as {type, message}}
  {#await message then m}
    <MessageBox {type} {cta} {onCta}>{@html m}</MessageBox>
  {/await}
{/each}