<script>
    import TurnstileInput from "~/components/TurnstileInput.svelte";

    /**
     * @typedef {Object} Props
     * @property {string} [turnstileKey]
     * @property {string} [action]
     * @property {import('svelte').Snippet<[any]>} [children]
     */

    /** @type {Props} */
    let { turnstileKey = "", action = "unknown", children } = $props();
    let completed = $state(false);
    let requiresInteraction = $state(false);
</script>

<section>
    <TurnstileInput {turnstileKey} {action} onCompleted={() => {completed = true}} onInteractionRequired={() => {requiresInteraction = true}} />
    {@render children?.({ completed, requiresInteraction, })}
</section>

<style>

</style>