<script>
    import {isSupported, shouldPrioritizeWebauthn} from "~/common/webAuthn.js";
    import localizer from "~/common/i18n.js";
    import WebauthnSudomodeButton from "~/components/webauthn/WebauthnSudomodeButton.svelte";
    import Button from "~/components/Button.svelte";
    import ForgotPassword from "~/components/links/ForgotPassword.svelte";
    import FlashMessage from "@/components/FlashMessage.svelte";
    import Form from "@/components/Form.svelte";
    import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";
    import LoginBox from "~/components/LoginBox.svelte";

    let {
        username,
        name,
        sendCodeUrl,
        webauthnPrepareRegistrationUrl,
        verifySudoByOtpCredentialUrl,
        logoutUrl,
        filteredParams = {},
        credentialTypes = []
    } = $props();

    let prioritizeWebauthn = $state(undefined);
    shouldPrioritizeWebauthn().then((p) => prioritizeWebauthn = p);

    let userHasWebauthn = credentialTypes.includes('WEBAUTHN');
    let webauthnIsSupported = $state();

    isSupported().then(supported => webauthnIsSupported = supported);

    const t = localizer({
        nb: {
            title: 'Ekstra sikkerhet',
            text: 'Du er logget inn som <strong>{{name}}</strong>. Siden du er på vei til inneholder personopplysninger, og som en ekstra sikkerhet ber vi derfor om at du identifiserer deg på nytt.',
            legacy_link: 'Bruk SMS i stedet',
            legacy_notice: 'Er du ikke <strong>{{name}}</strong>?',
            legacy_logout_link: 'Logg inn med en annen aID.',
            otpButtonPhone: "Få engangskode på SMS",
            otpButtonEmail: "Få engangskode på e-post",
            webauthnRegistrationButton: "Bruk passordfri"
        }
    })

</script>

<LoginBox title={t('title')} >
    <FlashMessage/>
    <p>{@html t('text', {name: name})}</p>
    {#if prioritizeWebauthn && webauthnIsSupported && userHasWebauthn}
        <div>
            <WebauthnSudomodeButton prio={true} />

            <ForgotPassword url={verifySudoByOtpCredentialUrl} username={username} />
        </div>
    {:else if prioritizeWebauthn !== undefined}
        <Form action={sendCodeUrl}>
            {#each Object.entries(filteredParams) as [key, value]}
                <input type="hidden" name="{key}" value="{value}" />
            {/each}
            <input type="hidden" name="username" value="{username}" />
            <input type="hidden" name="code_confirmed_url" value="{verifySudoByOtpCredentialUrl}" />

            <Button prio={true} logName="login_by_otp" type="submit">{username.includes('@') ? t('otpButtonEmail') : t('otpButtonPhone')}</Button>
        </Form>

        {#if webauthnIsSupported}
            {#if userHasWebauthn}
                <WebauthnSudomodeButton prio={false} />
            {:else}
                <Form action={sendCodeUrl}>
                    {#each Object.entries(filteredParams) as [key, value]}
                        <input type="hidden" name="{key}" value="{value}" />
                    {/each}
                    <input type="hidden" name="code_confirmed_url" value="{webauthnPrepareRegistrationUrl}" />

                    <input type="hidden" name="username" value="{username}" />
                    <WebauthnButton prio={false} logName="start_webauthn_registration" type="submit">{t('webauthnRegistrationButton')}</WebauthnButton>
                </Form>
            {/if}
        {/if}
    {/if}
    <p>
        {@html t('legacy_notice', {name: name})}
        <a href={logoutUrl}>{t('legacy_logout_link')}</a>
    </p>
</LoginBox>

<style>
    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
</style>